<template>
  <!-- Popup confirm pin -->

  <div
    class="popup-container align-items-center test"
    v-if="blnShowPopup == true && intWrongLogin < intPinCount"
  >
    <!-- popup -->
    <!-- <transition name="zoom" appear> -->
    <Alert
      v-if="blnShowPopup == true && intWrongLogin < intPinCount"
      type="Warning"
      classOkBtn="btn-secondary"
      artIcon="WarningOutline"
      :altMsgBox="$t(altMsgBox)"
      :Helper="$t('355')"
      ref="AlertPopup"
      @confirm="onConfirm($event)"
      :blnLoading="blnloadConfirmDelet"
      :showpass="true"
      :btnpop="true"
      classIcon="fill-secondary"
      :errorMsg="$t(errorMsgPopup)"
      :blnError="blnError"
      :sub="$t(sub)"
    />
    <!-- </transition> -->
    <!-- <transition name="fade" appear> -->
    <div
      class="overlay"
      v-if="blnShowPopup == true && intWrongLogin < intPinCount"
      @click="blnloadConfirmDelet == false ? onConfirm(false) : ''"
    ></div>
    <!-- </transition> -->
  </div>
  <!-- popup go to login -->
  <div
    class="popup-container align-items-center"
    v-if="blnShowPopup == true && intWrongLogin >= intPinCount"
  >
    <!-- <transition name="zoom"> -->
    <Alert
      v-if="blnShowPopup == true && intWrongLogin >= intPinCount"
      type="Warning"
      classOkBtn="btn-secondary"
      artIcon="WarningOutline"
      :altMsgBox="$t('356', { number: intPinCount, phoneno: strTel })"
      :Helper="$t('345')"
      @confirm="reloadpage()"
      ref="AlertPopup"
      :btnpop="true"
      classIcon="fill-warning"
      :Cancel="false"
    />
    <!-- </transition> -->
    <!-- <transition name="fade"> -->
    <div
      class="overlay"
      v-if="blnShowPopup == true && intWrongLogin >= intPinCount"
      @click="reloadpage()"
    ></div>
    <!-- </transition> -->
  </div>
  <!-- Selector -->
  <!-- {{ updateLang }}
  {{ strLang }}
  {{ $i18n.locale }} -->
  <div
    class="dropdown w-100"
    :class="size"
    v-if="strLang == $i18n.locale"
    :id="'dropdown' + strName + strGroupID"
  >
    <div
      class="select rounded-2"
      @click="
        () => {
          blnDropdown = !blnDropdown;
          this.$emit('click');
        }
      "
      :class="
        blnDropdown
          ? 'active'
          : '' || error
          ? 'error'
          : '' || strResult != ''
          ? 'have-data'
          : ''
      "
      v-if="reloader == true ? false : true && inactive == false"
    >
      <label
        for="label"
        class="d-flex align-items-center"
        :style="device == 'mobile' ? 'font-size:12px !important;' : ''"
        style="max-width: 70%"
        :class="
          strResult != '' && blnDropdown == false
            ? 'text-black-500 label'
            : '' || blnDropdown
            ? 'label'
            : '' || error
            ? 'text-danger'
            : '' || device == 'mobile'
            ? 'fnt-caption'
            : ''
        "
        ><div class="d-inline-block text-truncate">
          {{ blnSystemword == false ? $t(strName) : $t(strName) }} {{ star }}
        </div>
      </label>
      <div class="h-100 d-flex align-items-center">
        <input
          type="text"
          name=""
          id=""
          class="text-black"
          readonly
          :value="blnSystemword == true ? $t(strResult) : strResult"
        />
      </div>

      <Button
        style="z-index: 10"
        v-if="btnRemove == true"
        icon="CloseOutline"
        class="close-dropdown me-4"
        classBtnIcon="fill-black-500"
        :class="strResult == '' || strResult == [] ? 'd-none' : ''"
        @click="removeSelect()"
      />
      <div
        @click="deg += 360"
        class="reload"
        :style="{ transform: `rotate(${deg}deg)` }"
        v-if="reloader"
      >
        <Icon icon="uiw:reload" class="p-0 m-0" />
      </div>

      <Icon
        :class="
          reloader ? 'd-none' : '' || blnDropdown ? 'icon-dropdown-active' : ''
        "
        icon="akar-icons:chevron-down"
        @click="checkLoad"
        class="icon-dropdown text-black-500 tetx-secondary"
      />
    </div>
    <!-- Disabled -->

    <div
      v-if="inactive == true"
      class="rounded-2 disable w-100"
      :class="blnTooltip ? 'tooltiper tooltip-bottom' + size : '' + size"
      :data-tooltip="$t(tooltipMsg)"
    >
      <label
        for="label"
        class="d-flex align-items-center"
        :class="strResult != '' ? 'text-black-500 label' : ''"
        style="max-width: 70%"
        ><div
          class="d-inline-block text-truncate"
          :class="device == 'mobile' ? 'fnt-caption' : ''"
        >
          {{ $t(strName) }}{{ star }}
        </div></label
      >
      <input
        type="text"
        name=""
        id=""
        readonly
        :value="strResult == '' || strResult == undefined ? '' : $t(strResult)"
        disabled
      />
      <Icon
        :class="
          reloader ? 'd-none' : '' || blnDropdown ? 'icon-dropdown-active' : ''
        "
        icon="akar-icons:chevron-down"
        @click="checkLoad"
        class="icon-dropdown text-black-500 tetx-secondary"
      />
    </div>
    <!-- Reload -->
    <div class="select rounded-2 error" v-if="reloader">
      <label for="label">Dropdown</label>
      <input type="text" name="" id="" readonly :value="strResult" />
      <div
        @click="deg += 360"
        class="reload"
        :style="{ transform: `rotate(${deg}deg)` }"
      >
        <Icon icon="uiw:reload" class="p-0 m-0" />
      </div>
    </div>
    <div class="w-100" v-if="reloadlist == true">
      <!-- Dropdown list -->
      <transition name="dropdown">
        <div
          class="rounded-2 bg-white shadow-sm"
          :class="
            btnMoveTop == true
              ? 'list-top'
              : error == true
              ? 'list-error'
              : 'list'
          "
          :style="'width:' + size"
          v-show="blnDropdown"
          v-if="
            (List == true && empty(DropdownItem) == true) ||
            empty(listdropdownitem) == true
          "
        >
          <ListDropdown
            :blnselected="blnselected"
            :selectedValue="
              selectedValue == undefined ? strbeforeSelected : selectedValue
            "
            :selectedLookup="selectedLookup"
            :blnSystemword="blnSystemword"
            :items="DropdownItem.length == 0 ? listdropdownitem : DropdownItem"
            @onChange="changeResult($event)"
            :defaultLang="defaultLang"
            :blnAddress="blnAddress"
            :enable="enable"
            :enablelist="enablelist"
            :hint="strName"
            :blnSearch="blnSearch"
            ref="Dropdown"
          />
        </div>
      </transition>
      <!-- Dropdown Checkbox -->
      <transition name="dropdown">
        <div
          class="list rounded-2 bg-white shadow-sm"
          v-show="blnDropdown"
          v-if="Checkbox == true && empty(DropdownItem) == true"
        >
          <CheckboxDropdown
            :blnselected="blnselected"
            :selectedValue="selectedValue"
            :selectedCheckbox="selectedCheckbox"
            :blnSystemword="blnSystemword"
            :listCheckboxDropdown="DropdownItem"
            :hint="strName"
            :blnSearch="blnSearch"
            @checkshow="selectShow"
            @getID="getID($event)"
            ref="Dropdowncheckbox"
          />
        </div>
      </transition>
      <!-- Dropdown tree -->
      <transition name="dropdown">
        <div
          class="tree rounded-2 bg-white"
          v-show="blnDropdown"
          v-if="Tree == true && empty(DropdownItem) == true"
        >
          <TreeDropdwon
            :blnselected="blnselected"
            :selectedValue="selectedValue"
            :blnSystemword="blnSystemword"
            :treeData="DropdownItem"
            @onLevFt="showLevFt($event)"
            @onLevSn="showLevSn($event)"
            @getID="getID($event)"
          />
        </div>
      </transition>
      <!-- Error message -->
    </div>
    <div
      class="d-flex text-danger align-items-center mt-1 mb-3 fnt-regular fnt-body"
      v-if="error == true"
    >
      <!-- <Icon icon="ep:circle-close-filled" width="16" height="16" class="me-1" /> -->
      <InfoOutline width="16" height="16" class="me-2" />
      {{ $t(errorMsg) }}
    </div>
  </div>
  <!-- {{ DropdownItem }} -->
  <!-- Dropdown Tree -->
  <!-- <div class="overlay" v-if="blnDropdown" @click="()=>{blnDropdown = false; this.$refs.Dropdown.clear();}"></div> -->
</template>

<script>
import Alert from "@/components/alert/alertPopup.vue";
import { InfoOutline } from "@/components/icon/index";
import TreeDropdwon from "./tree.vue";
import ListDropdown from "./lists.vue";
import CheckboxDropdown from "./checkbox.vue";
import { Icon } from "@iconify/vue";
import Button from "@/components/button/button.vue";
import axios from "axios";
import urlapi from "@/typescript/urlapi/url";
import AdsInventories from "@/typescript/inventory";
import Login from "@/typescript/login";
import customfilter from "@/typescript/filter";
import VueCookies from "vue-cookies";
import cookiesPath from "@/typescript/urlapi/cookiesPath";

export default {
  props: {
    List: { type: Boolean, default: false },
    Checkbox: { type: Boolean, default: false },
    Tree: { type: Boolean, default: false },
    DropdownItem: { type: Object },
    reloader: { type: Boolean, default: false },
    blnTooltip: { type: Boolean, default: false },
    tooltipMsg: { type: String, default: "" },
    tooltipDelay: { type: String, default: "150ms" },
    tooltipPlacement: { type: String, default: "tooltip-buttom" },
    blnSystemword: { type: Boolean, default: true },
    strName: { type: String },
    selectedValue: { type: String },
    blnselected: { type: Boolean, default: false },
    showTxtInp: { type: Boolean, default: false }, // Input disabled
    size: { type: String, default: "100%" }, // Size of lists dropdown
    star: { type: String, default: "" },
    // error:{type:Boolean,default:false},
    defaultLang: { type: String, default: null },
    blnAddress: { type: Boolean, default: false },
    alertMsg: { type: String },
    altMsgBox: { type: String },
    sub: { type: String, default: "" },
    btnRemove: { type: Boolean, default: true },
    btnMoveTop: { type: Boolean, default: false },
    blnSearch: { type: Boolean, default: true },
    selectedLookup: { type: Object },
    blnChangelang: { type: Boolean, default: false },
    selectedCheckbox: { type: Array },
    strGroupID: { type: String },
  },
  data() {
    return {
      blnloadConfirmDelet: false,
      blnShowPopup: false,
      blnConfirm: false,
      listdropdownitem: [],
      enable: false,
      enablelist: false,
      inactive: false,
      intId: 0,
      blnDropdown: false,
      blnResult: null,
      strResult: "",
      // clear: null,
      reload: false,
      strLoad: "",
      deg: 0,
      disable: false,
      alertMsgStatus: false,
      errorMsg: { type: String, default: "" },
      error: false,
      strAddressEN: "",
      intWrongLogin: 0,
      blnError: false,
      intPinCount: 1,
      listResult: [],
      strbeforeSelected: null,
      strLang: null,
      reloadlist: true,
      strTel: "",
      errorMsgPopup: "",
    };
  },
  components: {
    Icon,
    Button,
    ListDropdown,
    CheckboxDropdown,
    TreeDropdwon,
    InfoOutline,
    Alert,
  },
  watch: {
    selectedLookup: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue != "" && newValue != oldValue && newValue != undefined) {
          this.changeResult(newValue);
        } else this.strResult = "";
      },
    },
    selectedValue: {
      deep: true,
      handler(newValue, oldValue) {
        // console.log(this.strName, this.DropdownItem);
        if (
          newValue != "" &&
          newValue != oldValue &&
          this.DropdownItem != undefined
        ) {
          if (typeof newValue == "object") {
            this.changeResult(newValue);
          } else {
            if (typeof this.DropdownItem != "object") {
              this.DropdownItem?.filter((e) => {
                if (e.id == newValue || e.strID == newValue) {
                  this.changeResult(e);
                }
              });
            }
          }
        } else if (typeof newValue == "string") {
          if (newValue == "") {
            this.strResult = "";
          }
        }
        // else this.strResult = "";
      },
    },
    showTxtInp: {
      deep: true,
      handler(newValue) {
        this.inactive = newValue;
      },
    },
    strLang: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue != null && oldValue != null && newValue != oldValue) {
          try {
            if (typeof this.DropdownItem != "object") {
              this.DropdownItem?.forEach((e) => {
                if (
                  this.selectedValue != "" &&
                  this.selectedValue?.id != null &&
                  this.selectedValue?.id != undefined &&
                  e.strID == this.selectedValue.id &&
                  this.blnChangelang == true
                ) {
                  // console.log(e);
                  // console.log(this.selectedValue);
                  // console.log(this.blnChangelang);
                  this.changeResult({
                    id: e.strID,
                    lang: { langEN: e.strNameEN, langTH: e.strNameTH },
                  });
                }
              });
            } else {
              Object.values(this.DropdownItem)?.forEach((e) => {
                if (
                  this.selectedValue != "" &&
                  this.selectedValue?.id != null &&
                  this.selectedValue?.id != undefined &&
                  e.strID == this.selectedValue.id &&
                  this.blnChangelang == true
                ) {
                  // console.log(e);
                  //
                  // console.log(this.blnChangelang);
                  this.changeResult({
                    id: e.strID,
                    lang: { langEN: e.strNameEN, langTH: e.strNameTH },
                  });
                } else if (
                  this.blnChangelang == true &&
                  this.selectedValue != "" &&
                  this.selectedValue?.id?.intDistrictID != undefined &&
                  this.selectedValue?.id?.intDistrictID == e?.intDistrictID &&
                  this.selectedValue?.id?.intProvinceID == e?.intProvinceID &&
                  this.selectedValue?.id?.intSubdistrictID ==
                    e?.intSubdistrictID &&
                  this.selectedValue?.id?.strPostcode == e?.strPostcode
                ) {
                  setTimeout(() => {
                    this.changeResult({
                      id: {
                        intDistrictID: e.intDistrictID,
                        intProvinceID: e.intProvinceID,
                        intSubdistrictID: e.intSubdistrictID,
                        strPostcode: e.strPostcode,
                      },
                      lang: { langEN: e.strNameEN, langTH: e.strNameTH },
                    });
                  }, 1000);
                }
              });
            }
          } catch (error) {
            console.log(error);
          }
        }
      },
    },
  },
  emits: ["input", "load", "click"],

  updated() {
    this.strLang = this.$i18n.locale;
  },
  beforeMount() {
    this.strbeforeSelected =
      typeof this.selectedValue == "string"
        ? Number(this.selectedValue)
        : this.selectedValue;
    // console.log(this.selectedValue);
  },
  mounted() {
    if (this.strbeforeSelected == null || this.strbeforeSelected == undefined) {
      setTimeout(() => {
        this.strbeforeSelected = this.selectedValue;
        // console.log(this.strbeforeSelected);
      }, 500);
    }

    this.strLang = this.$i18n.locale;
    setTimeout(() => {
      this.disable = this.showTxtInp;
      // this.inactive = this.showTxtInp;
    }, 200);

    if (this.inactive !== true) {
      this.inactive = this.showTxtInp;
    }
    this.$emit("load");

    this.onClickOutSide();
  },
  methods: {
    // click Outside (Overlay)
    onClickOutSide() {
      const handleMouseDown = (e) => {
        let clickOutside = true;
        let el = e.target;
        while (el.parentElement) {
          if (el.id === "dropdown" + this.strName + this.strGroupID)
            clickOutside = false;
          el = el.parentElement;
        }
        if (clickOutside == true) {
          this.blnDropdown = false;
          if (this.$refs.Dropdown != undefined) {
            this.$refs.Dropdown.clear();
          }
        }
      };
      document.addEventListener("mousedown", handleMouseDown);
    },
    fetchLoad() {
      // this.removeSelect();
      this.reloadlist = false;

      // console.log(this.$refs.Dropdown);

      // this.clear = true;
      this.$emit("input", "");
      this.$emit("load");
      setTimeout(() => {
        this.reloadlist = true;
      }, 100);
      setTimeout(() => {
        this.$refs.Dropdown.clear();
        // this.removeSelect();
        // this.blnDropdown = true;
        this.strResult = "";
      }, 200);
    },
    async onConfirm(state) {
      try {
        this.blnError = false;
        // this.blnShowPopup = false;
        this.blnloadConfirmDelet = true;

        if (state != false && state != null && state != "") {
          let _logindata = {
            username: AdsInventories.userinfo.strUsername,
            password: state,
          };

          // console.log(_logindata);
          if (_logindata.password != null) {
            var strUsername = AdsInventories.userinfo.strUsername.split("@");
            var res = await Login.login(strUsername[0], state, axios, urlapi);
          }
          this.blnloadConfirmDelet = false;
          // console.log(res);
          //ถ้า login สำเร็จ จะปิด popup และ ค่าของ toggle จะเปลี่ยนไปถามที่เลือก

          if (res.logined == true) {
            this.intPinCount = res.pincode;
            this.strTel = res.tel;
            this.blnError = false;
            this.blnShowPopup = false;
            this.strbeforeSelected =
              (this.blnAddress == false ? this.strResult : this.listResult,
              this.intId);
            this.$emit("input", {
              value:
                (this.blnAddress == false ? this.strResult : this.listResult,
                this.intId),
              confirm: true,
            });
            // this.$emit("input", { value: this.checkshow, confirm: true });
            return true;
          } else {
            // this.intWrongLogin += 1;
            this.intPinCount = res.pincode;
            this.strTel = res.tel;
            this.intWrongLogin = res.WrongLogin;
            this.errorMsgPopup = this.$t("357", {
              number: this.intPinCount - this.intWrongLogin,
            });
            this.blnShowPopup = true;
            this.blnError = true;
            this.blnloadConfirmDelet = false;
            if (this.intWrongLogin >= this.intPinCount) {
              VueCookies.remove("UserInfo", "/", cookiesPath);
            }
          }
        }
        //ถ้า login ไม่สำเร็จหรือกด Cancel ค่าจะเปลี่ยนกลับไปเป็นค่าก่อนหน้า
        else {
          this.blnloadConfirmDelet = false;
          if (state == false) {
            this.blnShowPopup = false;
            // console.log(this.strbeforeSelected);
            //this.selectedValue = this.strbeforeSelected;
            setTimeout(() => {
              this.$refs.Dropdown.resetData();
            }, 200);

            // this.$refs.Toggle.checked = !this.checkshow;

            // this.$emit("input", {
            //   value: !this.checkshow,
            //   confirm: false,
            // });
            return true;
          } else if (state == null || state == "") {
            this.errorMsgPopup = this.$t("202", { fieldname: "(PIN)" });
            this.blnShowPopup = true;
            this.blnError = true;
          }
        }
      } catch (error) {
        this.blnloadConfirmDelet = false;
        console.log(error);
      }
    },
    reloadpage() {
      Login.logout();

      // localStorage.setItem("LoginState", false);

      // //บันทึกชื่อผู้ใช้งานไปยัง store
      // localStorage.setItem("username", null);
      // localStorage.clear();
      // //ไปยังหน้าแรก
      // setTimeout(() => {
      //   this.$router.push("/login");
      //   this.$router.go();
      // }, 100);
    },
    Valid() {
      if (
        this.strResult == "" ||
        this.strResult == null ||
        this.strResult == undefined
      ) {
        this.alertRequest();
      } else {
        this.alertMsgStatus = false;
      }
    },
    alertRequest() {
      if (
        this.strResult == "" ||
        this.strResult == null ||
        this.strResult == undefined
      ) {
        this.errorMsg = this.$t(this.alertMsg, {
          fieldname: this.$t(this.strName).toLowerCase(),
        });
        // console.log(this.errorMsg);
        this.alertMsgStatus = true;
        this.error = this.alertMsgStatus;
        // console.log(this.alertMsgStatus);
      } else {
        this.alertMsgStatus = false;
      }
    },
    showLevFt(event) {
      this.strResult = event;
    },
    showLevSn(event) {
      this.strResult = event;
    },
    checkLoad() {
      this.reload = !this.reload;
      // console.log(this.reload);
    },
    changeResult(event) {
      try {
        this.error = false;
        if (
          this.defaultLang == undefined ||
          this.defaultLang == null ||
          this.defaultLang == ""
        ) {
          this.strResult =
            this.blnAddress == false
              ? event.lang
              : event.lang != undefined
              ? event.lang[
                  customfilter.filterLang(
                    event.lang,
                    this.defaultLang == "" ? this.strLang : this.defaultLang
                  )
                ]
              : event[
                  customfilter.filterLang(
                    event,
                    this.defaultLang == "" ? this.strLang : this.defaultLang
                  )
                ];
        } else if (
          this.defaultLang != undefined &&
          this.defaultLang != null &&
          this.defaultLang != ""
        ) {
          this.strResult =
            this.blnAddress == false
              ? event.lang
              : event.lang != undefined
              ? event.lang[
                  customfilter.filterLang(event.lang, this.defaultLang)
                ]
              : event[customfilter.filterLang(event, this.defaultLang)];
        }
        if (this.blnAddress == true) {
          this.strAddressEN = event?.lang?.langEN;
        }
        this.intId = this.blnAddress == false ? event.id : event;
        this.blnDropdown = false;
        // console.log(this.blnConfirm);
        // console.log(event.id + " : " + this.strbeforeSelected);
        if (this.blnConfirm == true && event.id != this.strbeforeSelected) {
          this.listResult = event;
          this.blnShowPopup = true;
        } else if (
          this.blnConfirm == false ||
          event.id == this.strbeforeSelected
        ) {
          this.$emit("input", {
            value:
              (this.blnAddress == false ? this.strResult : this.listResult,
              this.intId),
            confirm: false,
          });
        }
        // console.log("Yes");
      } catch (e) {
        console.error(event, e);
      }
    },
    removeSelect() {
      this.$refs.Dropdown?.clear();
      this.$refs.Dropdowncheckbox?.onclear();
      this.blnDropdown = true;
      this.strResult = "";
      // this.clear = true;
      this.$emit("input", "");
    },
    selectShow(event) {
      this.strResult = event;
      // console.log(this.strResult);
    },
    getID(event) {
      // console.log(event);
      this.intId = event;
      this.$emit("input", {
        value: (this.strResult, this.intId),
        confirm: false,
      });
    },

    empty(array) {
      if (
        typeof array != "undefined" &&
        array != null &&
        Object.values(array).length > 0
      ) {
        return true;
        // array exists and is not empty
      } else {
        return false;
      }
    },
    // resetData(e){
    //   console.log(this.$refs['Dropdown'])
    //   this.$refs['Dropdown'].strResult= 'e.lang?.langTH || e.value?.lang?.langTH'
    // }
  },
};
</script>

<style lang="scss" scoped>
.label {
  top: -16% !important;
  transform: translateY(-16%) !important;
  font-size: 14px;
  transition: 0.15s !important;
  left: 8px !important;
  //   color: $primary !important;
  padding: 0px 4px !important;
  border-radius: 4px;
  background-color: $white !important;
}
.label-mobile {
  top: -16% !important;
  transform: translateY(-16%) !important;
  font-size: 12px;
  transition: 0.15s !important;
  left: 8px !important;
  //   color: $primary !important;
  padding: 0px 4px !important;
  border-radius: 4px;
  background-color: $white !important;
}
.active {
  border: solid 1px $primary !important;
  label {
    color: $primary !important;
  }
}
.have-data {
  &:hover {
    .label {
      color: $primary;
    }
  }
}
.icon-dropdown-active {
  transform: rotate(180deg) translateY(50%) !important ;
  top: 50%;
}
.reloader {
  border: solid 1px $danger !important;
  .icon-dropdown {
    transform: rotate(0deg) translateY(-50%) !important ;
    top: 50% !important;
  }
  label {
    color: $danger !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
}
.error {
  border: solid 1px $danger !important;
  .icon-dropdown {
    transform: rotate(0deg) translateY(-50%) !important ;
    top: 50% !important;
  }
  label {
    color: $danger !important;
  }
}
.error-selected {
  border: solid 1px $danger !important;
  .icon-dropdown {
    transform: rotate(180deg) translateY(50%) !important ;
    top: 50% !important;
  }
  label {
    color: $danger !important;
  }
}
.medium {
  .select {
    height: 38px !important;
    label {
      font-size: 14px;
    }
  }
  .disable {
    height: 38px !important;
    label {
      font-size: 14px;
    }
  }
}
.dropdown {
  user-select: none;
  // position: relative;
  &:hover {
    .close-dropdown {
      opacity: 1 !important;
    }
  }
  .disable {
    background-color: $black-200 !important;
    z-index: 0;
    border: solid 1px $black-400;
    height: 48px;
    // width: 80%;
    position: relative;
    cursor: pointer;
    // z-index: 10;
    transition: 150ms;
    &:hover {
      border: solid 1px $black-400;
    }
    &:active {
      background-color: $black-300;
    }
    input[type="text"] {
      width: 80%;
      outline: none;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      padding-left: 16px;
      padding-top: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      z-index: 10;
      background-color: transparent;
      // background-color: $danger;
      &:hover {
        .close-dropdown {
          opacity: 1 !important;
        }
      }
    }
    label {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      padding: 2px 0;
      transition: 0.15s;
      background-color: $black-200 !important;
      color: $black-500;
    }
    span {
      position: absolute;
      top: 50%;
      left: 16px;
      transition: 150ms;
      transform: translateY(-50%);
    }
    .icon-dropdown {
      padding: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      transition: 0.15s;
    }
    .reload {
      right: 16px;
      transition: 0.7s;
      color: $secondary;
      position: absolute;
      transform: translateY(-20%);
      top: 20%;
      // svg {
      //   top: 50%;
      //   left: 50%;
      //   position: absolute;
      //   transform: translateY(-50%, -50%);
      // }
    }
    .tooltip-bottom {
      &::before {
        left: 50% !important;
        top: 110% !important;
        transform: translateX(50%) !important;
      }
      &::after {
        left: 47%;
        bottom: calc(0% - 8px);
        transform: translateX(-50%);
      }
    }
    .close-dropdown {
      padding: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      opacity: 0;
      transition: 150ms;
    }
  }

  .select {
    z-index: 0;
    border: solid 1px $black-500;
    height: 48px;
    // width: 80%;
    position: relative;
    background-color: $white;
    cursor: pointer;
    // z-index: 10;
    transition: 150ms;
    &:hover {
      border: solid 1px $primary;
    }
    &:active {
      background-color: $black-300;
    }
    input[type="text"] {
      width: 80%;
      outline: none;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      padding-left: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      z-index: 10;
      background-color: transparent;
      // background-color: $danger;
      &:hover {
        .close-dropdown {
          opacity: 1 !important;
        }
      }
    }
    label {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      padding: 2px 0;
      transition: 0.15s;
      background-color: transparent;
      color: $black-500;
    }
    span {
      position: absolute;
      top: 50%;
      left: 16px;
      transition: 150ms;
      transform: translateY(-50%);
    }
    .icon-dropdown {
      padding: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      transition: 0.15s;
    }
    .reload {
      right: 16px;
      transition: 0.7s;
      color: $secondary;
      position: absolute;
      transform: translateY(-20%);
      top: 20%;
      // svg {
      //   top: 50%;
      //   left: 50%;
      //   position: absolute;
      //   transform: translateY(-50%, -50%);
      // }
    }
    .close-dropdown {
      padding: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      opacity: 0;
      transition: 150ms;
    }
  }
}
.tooltiper {
  position: relative;
  &:hover {
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
      transition-property: opacity visibility;
      transition-duration: 150ms;
      transition-delay: v-bind("tooltipDelay");
    }
  }
  &::before {
    z-index: 9999;
    position: absolute;
    // left: 50%;
    top: 110%;
    // transform: translate(-50%, -50%);
    content: attr(data-tooltip);
    color: $white;
    background-color: $black;
    padding: 8px;
    border-radius: 4px;
    width: max-content;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    top: 110%;
    transition-property: opacity visibility;
    transition-duration: 150ms;
    white-space: pre;
    // transition-delay: v-bind("delay");
  }
  &::after {
    position: absolute;
    left: 8%;
    top: 100%;
    // transform: translate(-50%, -50%);
    z-index: 9999;
    content: "";
    background-color: $black;
    width: 9px;
    height: 9px;
    transform: rotate(-45deg);
    opacity: 0;
    visibility: hidden;
    transition-property: opacity visibility;
    transition-duration: 100ms;
    // transition-delay: v-bind("delay");
  }
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: transparent;
  left: 0;
  top: 0;
  // z-index: 3;
}
.list-error {
  position: absolute;
  top: 75%;
  left: 0;
  width: 100%;
  // filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.02))
  //   drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.04));

  z-index: 11;
}
.list {
  position: absolute;
  top: 113%;
  left: 0;
  width: 100%;
  // filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.02))//‼️สิ่งนี้ทำให่เกิดการเบลอ
  //   drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.04));

  z-index: 11;
}
.list-top {
  // transform: translateY(0%);
  // transition: 0.2s;
  position: absolute;
  bottom: 120%;
  left: 0;
  width: 100%;
  // filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.02))
  //   drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.04));

  z-index: 11;
}
.tree {
  position: absolute;
  top: 113%;
  width: 100%;
  z-index: 11;
}
.item {
  height: 44px;
  background-color: $white;
  padding: 12px 16px;
  cursor: pointer;
  transition: 150ms;
  &:hover {
    background-color: $black-50;
  }
  &:active {
    background-color: $black-300;
  }
}
// Transition
.dropdown-enter-active,
.dropdown-leave-active {
  transition: opacity 150ms ease;
}

.dropdown-enter-from,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}

.popup-container {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: start;
  justify-content: center;
  .overlay {
    background-color: rgba(0, 0, 0, 0.263);
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
</style>
