<template>
  <div class="p-2" v-if="blnSearch">
    <Search
      :hint="$t('447', { fieldname: $t(hint).toLowerCase() })"
      @onSearch="searchDropdown($event)"
      @onClean="clear()"
    />
  </div>
  <div class="list-items">
    <label
      v-for="(item, index) in listCheckboxDropdown"
      :key="index"
      class="item position-relative"
      :for="
        blnSystemword == true
          ? item.lang + index
          : item[fillterLang(item)] + index
      "
    >
      <!-- <Checkbox
      :checkboxLabel="item.lang+index"
      fontSize="fnt-regular fnt-subtitle"
      @select="selected($event)"
      @change="selection(item, index)"
    /> -->
      <input
        type="checkbox"
        class="me-2 form-check-input"
        :id="
          blnSystemword == true
            ? item.lang + index
            : item[fillterLang(item)] + index
        "
        @change="selected(item)"
        ref="checkbox"
      />
      <label
        :for="
          blnSystemword == true
            ? item.lang + index
            : item[fillterLang(item)] + index
        "
      >
        {{ blnSystemword == true ? item.lang : item[fillterLang(item)] }}
      </label>
    </label>
  </div>
  <!-- {{ selection() }} -->
</template>

<script>
import Search from "@/components/input/inputSearch.vue";
import customfilter from "@/typescript/filter";
// import Checkbox from "@/components/input/checkbox.vue";
export default {
  data() {
    return {
      strResult: [],
      checkDrd: [],
      locales: "en",
      items: [],
    };
  },
  emits: ["checkshow", "getID"],
  beforeUpdate() {
    this.locales = localStorage.getItem("language");
    //ถ้าไม่เจอค่า
    if (this.locales == null || this.locales == "") {
      //ตั้งค่าเริ่มต้นเป็นภาษาอังกฤษ
      this.locales = "en";
    }
  },
  mounted() {
    setTimeout(() => {
      this.items = [...this.listCheckboxDropdown];
      this.resetData();
    }, 1000);
  },
  watch: {
    selectedValue: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          this.resetData();
          // console.log(this.selectedValue);
        }
      },
    },
    selectedCheckbox: {
      deep: true,
      handler(newValue) {
        console.log(newValue);
        this.resetData();
      },
    },
  },
  methods: {
    onclear() {
      this.checkDrd = [];
      this.$refs.checkbox?.forEach((element) => {
        element.checked = false;
      });
    },
    resetData() {
      Object.values(this.items).forEach((e) => {
        if (this.selectedCheckbox) {
          Object.values(this.selectedCheckbox).forEach((ele) => {
            if (e.id == ele) {
              // console.log(e);
              let test = e.lang;
              this.checkDrd.push(test);
              this.selected(e);
            }
          });
        }
      });
    },
    selected(event) {
      this.listCheckboxDropdown.forEach((e) => {
        // ดึงค่าที่ต้องใช้
        const value = e[this.$i18n.locale === "th" ? "nameTH" : "nameEN"];
        const id = e.strID;

        // ค้นหาใน checkDrd โดยอิง id
        const existingIndex = this.checkDrd.findIndex((item) => item.id === id);

        if (e.strID === event.strID) {
          if (existingIndex === -1) {
            // เพิ่มค่าใหม่ถ้ายังไม่มี
            this.checkDrd.push({ id, value });
          } else {
            // ลบค่าออกถ้ามีอยู่แล้ว
            this.checkDrd.splice(existingIndex, 1);
          }
        }
      });

      // แปลง checkDrd ให้เป็นข้อความใน strResult
      this.strResult = this.checkDrd.map((item) => item.value).join(", ");

      // ส่งค่าออกไปผ่าน $emit
      this.$emit("checkshow", this.strResult);
      this.$emit("getID", event.id ? event.id : event.strID);
    },

    fillterLang(items) {
      if (items !== undefined) {
        return customfilter.filterLang(items, this.$i18n.locale);
      }
    },
    // },
  },
  components: {
    // Checkbox,
    Search,
  },
  props: {
    listCheckboxDropdown: { type: Object },
    blnSystemword: { type: Boolean, default: true },
    blnselected: { type: Boolean, default: false },
    selectedValue: { type: String },
    hint: { type: String },
    selectedCheckbox: { type: Array },
    // checkShow: { type: Object },
    blnSearch: { type: Boolean },
    check: null,
  },
};
</script>

<style lang="scss" scoped>
.list-items {
  max-height: calc(44px * 7);
  // min-height: fit-content;
  overflow-y: auto;
  .item {
    height: 44px;
    background-color: $white;
    padding: 12px 16px;
    cursor: pointer;
    transition: 150ms;
    display: flex;
    align-items: center;
    &:hover {
      background-color: $black-50;
    }
    &:active {
      background-color: $black-300;
    }
    label {
      &:hover {
        cursor: pointer;
      }
    }
  }
  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    border-radius: 2px !important;
    &:hover,
    &:focus {
      border: solid 1px $primary;
    }
  }
}
</style>
